import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avatar3.png";
import AVTR2 from "../../assets/avatar2.png";
import AVTR3 from "../../assets/avatar1.png";
import AVTR4 from "../../assets/avatar4.png";

// import Swiper core and required modules
import { Pagination} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: AVTR1,
    name: 'Capacitación en JAVA',
    review: 'Curso intensivo JAVA 8 y 11 dictado por el profesor Marcelo Arcidiacono. Algunos de los contenidos dictados fueron POO, Spring + microservicios, arq. REST, Bases de datos y testing, entre otros.'
  },
  {
    avatar: AVTR2,
    name: 'Curso NodeJS',
    review: 'Creacion de un servidor node con Express, CRUD, MongoDB.'
  },
  {
    avatar: AVTR3,
    name: 'React Hooks y MERN',
    review: 'Curso dictado por Fernando Herrera. Utilizando el stack Mongo, Express, React y node para crear aplicaciones web escalables.'
  },
  {
    avatar: AVTR4,
    name: 'Curso Git',
    review: 'Curso tomado individualmente para ampliar conocimientos del software de control de versiones.'
  }
]

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Informal Studies</h5>
      <h2>Courses</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index)=>{
            return(
              <SwiperSlide key={index} className="testimonial">
          <div className="client__avatar">
            <img src={avatar} alt="avatar" />
          </div>
          <h5 className="client__name">{name}</h5>
            <small className="client__review">
              {review}
            </small>
        </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  );
};

export default Testimonials;
