import React from 'react'
import './footer.css'
import { FaSquareGithub,FaSquareWhatsapp } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";




const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'></a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/jeremias-nu%C3%B1ez-151b27214/" target='_blank'><FaLinkedin /></a>
        <a href="https://api.whatsapp.com/send?phone=543541334034" target='_blank'><FaSquareWhatsapp /></a>
        <a href="https://github.com/Jere96" target='_blank'><FaSquareGithub /></a>
      </div> 

      <div className="footer__copyright">
        <small>&copy; Portfolio personal Jeremias Nuñez</small>
      </div>
    </footer>
  )
}

export default Footer
