import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio-3d.jpg";
import IMG3 from "../../assets/proyectogif.png";
import IMG4 from "../../assets/portfolio4.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Portfolio",
    github: "https://github.com/Jere96/Portfolio2024",
  },
  {
    id: 2,
    image: IMG2,
    title: "Jn-Portfolio 3D",
    github: "https://github.com/Jere96/jn-portafolio",
  },
  {
    id: 3,
    image: IMG3,
    title: "React-gif-expertapp",
    github: "https://github.com/Jere96/react-gif-expertapp",
  },
  {
    id: 4,
    image: IMG4,
    title: "Ecommerce",
    github: "https://github.com/Jere96/ecommerce",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} target="_blank" className="btn">
                  Github
                </a>
                
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
