import React from "react";
import "./services.css";
import { FaCheck } from "react-icons/fa";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Back End</h3>
          </div>

          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Experiencia desarrollando con el lenguaje JAVA (versiones 8 y 11) bajo el framework Spring</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Experiencia trabajando con arquitectura API REST, orientado a microservicios.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Experiencia realizando Unit Test, refactorización, calidad del código(SonarQube).</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Modelado y persistencia de datos (JPA).</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Conocimientos en bases de datos MongoDB, MySQL, PostgreSql.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Experiencia desarrollando con el lenguaje JavaScript bajo el framework ReactJS</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Creación, modificación y testing de componentes.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Manejo de estado del componente (REDUX).</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Estilizado de componentes con CSS, Bootstrap, Sass, Tailwind.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Experiencia trabajando con metodologias ágiles (SCRUM).</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Experiencia en responsive design.</p>
            </li>
          </ul>
        </article>
         {/* END OF WEB DEVELOPMENT */}
         <article className="service">
          <div className="service__head">
            <h3>Herramientas Utilizadas</h3>
          </div>

          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Control de versiones GIT.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>BitBucket.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Jira.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Lambda Test.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Jenkins.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Docker.</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Openshift.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
