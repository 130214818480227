import React from 'react'
import './nav.css'
import { FaHome } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { RiServiceFill } from "react-icons/ri";
import { MdContactMail } from "react-icons/md";
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')

  return (
    <nav>
      <a classname={activeNav ==='#' ? 'active' : ''} href="#" onClick={()=> setActiveNav('#')}><FaHome /></a>
      <a href="#about" onClick={()=> setActiveNav('#about')} className={activeNav ==='#about' ? 'active' : ''} ><FaUserTie /></a>
      <a href="#experience" onClick={()=> setActiveNav('#experience')} className={activeNav ==='#experience' ? 'active' : ''}><FaUserGraduate /></a>
      <a href="#services" onClick={()=> setActiveNav('#services')} className={activeNav ==='#services' ? 'active' : ''}><RiServiceFill /></a>
      <a href="#contact" onClick={()=> setActiveNav('#contact')} className={activeNav ==='#contact' ? 'active' : ''}><MdContactMail /></a>
    </nav>
  )
}

export default Nav
